@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

/*REBIN BACKGROUND COLORS*/

.bg-rebin-light-blue {
  background-color: #f2f5fd;
}

.bg-rebin-light-blue-1 {
  background-color: #f2f3f6;
}

.bg-rebin-soft-blue {
  background-color: #bacaf8;
}

.bg-rebin-blue {
  background-color: #0043ff;
}


.bg-rebin-dark-blue {
  background-color: #12274f;
}

.bg-rebin-grey {
  background-color: #a0aec0;
}

.bg-rebin-light-grey {
  background-color: #f0efed;
}

.bg-rebin-sidebar {
  background-color: #FAFAFA;
}


.bg-rebin-red {
  background-color: rgba(216, 0, 39, 0.05);
}

.bg-rebin-fail {
  background-color: #fed7d7;
}

.bg-rebin-success {
  background-color: #c6f6d5;
}

.bg-rebin-green {
  background-color: #eaf9f0;
}

.bg-rebin-orange {
  background-color: #f6e9de;
}



.bg-gradient {
  background: rgb(0, 67, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 67, 255, 1) 0%,
    rgba(18, 39, 79, 1) 100%
  );
}

.bg-rebin-hover:hover {
  background-color: #0055ff;
}

.bg-rebin-dark-blue-hover:hover {
  background-color: #12274f;
}

.bg-gradient-hover:hover {
  background: rgb(0, 67, 255);
  background: linear-gradient(
    30deg,
    rgba(0, 67, 255, 1) 0%,
    rgba(18, 39, 79, 1) 100%
  );
}

.bg-rebin-grey-hover:hover {
  background-color: #a0aec0;
}

.bg-rebin-light-grey-hover:hover {
  background-color: #dee5ec;
}

.bg-rebin-light-blue-hover:hover {
  background-color: #f2f5fd;
}

/*REBIN TEXT COLORS*/

.text-gradient {
  background: -webkit-linear-gradient(#0043ff, #12274f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-alert-color {
  color: #2d3748;
}

.text-rebin-darkblue {
  color: #12274f;
}

.text-rebin-blue {
  color: #0043ff;
}

.text-rebin-grey {
  color: #a0aec0;
}

.text-rebin-darkgrey {
  color: #696f79;
}

.text-rebin-red {
  color: #d80027;
}

.text-rebin-orange {
  color: #f56905
}

.text-rebin-green {
  color: #409C4f
}

.text-rebin-blue-hover:hover {
  color: #0043ff;
}

.text-rebin-light-blue {
  color: #f2f5fd;
}

/*REBIN BORDERS*/

.outline-rebin-blue {
  outline-color: #0043ff;
}

.border-rebin {
  border-style: solid;
  border-width: 1px;
  border-color: #dee5ec;
  outline-color: #0043ff;
}

.border-rebin-agenda {
  border-style: solid;
  border-width: 0.5px;
  border-color: #dee5ec;
  outline-color: #0043ff;
}

.border-rebin-table {
  border-bottom: 0.5px solid #dee5ec; /* Ensure solid border with visible width */
}


.border-rebin-bottom:focus {
  border-bottom: 1px solid #0043ff; /* Bottom border style when focused */
}

.border-rebin-blue-color {
  border-style: solid;
  border-width: 1px;
  border-color: #0043ff;
  outline-color: #0043ff;
}

.border-rebin-repeat {
  border-style: dashed	;
  border-width: 1px;
  border-color: #0043ff;
  outline-color: #0043ff;
}

.border-rebin-green {
  border-style: solid;
  border-width: 1px;
  border-color: #409C4f;
  outline-color: #0043ff;
}

.border-rebin-orange {
  border-style: solid;
  border-width: 1px;
  border-color: #f56905;
  outline-color: #0043ff;
}
#f56905

.border-rebin-grey {
  border-color: #DEE5EC;
}

.border-rebin-blue {
  border-color: #0043ff;
}

.border-rebin-blue-hover:hover {
  border-top: 1px solid #dee5ec;
  border-right: 1px solid #dee5ec;
  border-bottom: 1px solid #dee5ec;
  border-left: 2px solid #0043ff;
}

.border-rebin-dark-blue {
  border-color: #12274f;
  border-width: 2px;
}

.divide-rebin {
  border-bottom: 1px solid #dee5ec;
}

.rebin-password {
  font-size: 18px;
  letter-spacing: 4px;
}

.icon {
  stroke-width: 0;
}

.rdt_TableHeadRow {
  color: #a0aec0;
  font-weight: 700;
}

.rdt_TableCell {
  color: #12274f;
  font-weight: 500;
}

.items-between {
  align-items: space-between;
}

.multiselect__option:hover {
  background-color: #f2f5fd !important; /* This sets the hover background color */
}

/*REBIN TABLE*/

.asset-border-rebin {
  border-top: 0.5px solid #dee5ec;
  border-bottom: 0.5px solid #dee5ec;
}

.date-border-rebin {
  border-radius: 0.375rem;
  border-top: 0.5px solid #dee5ec;
  border-bottom: 0.5px solid #dee5ec;
  border-left: 0.5px solid #dee5ec;
  border-right: 0.5px solid #dee5ec;
}

/*-----*/
.shadow-left {
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

/* APEX CHARTS */

.apexcharts-tooltip {
  border: none !important;
  border-radius: 1rem !important;
}

.apexcharts-tooltip-title {
  background-color: white !important;
  border: none !important;
  color: #696f79 !important;
  padding: 1rem 2rem 0 2rem !important;
}

.apexcharts-tooltip-text {
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 1.5rem !important;
  line-height: normal !important;
}

.apexcharts-tooltip-series-group {
  padding-bottom: 0 !important;
}

.apexcharts-tooltip-y-group {
  padding: 0.5rem 0.5rem 0.25rem 0.5rem !important;
}

/* DATETIMEPICKER */

.react-aria-CalendarGridBody * {
  padding: 4px 0;
}

.react-aria-CalendarGridBody tr {
  overflow: clip;
}

.react-aria-CalendarGridBody tr td {
  width: 32px;
}

/* Custom CSS for inner shadow */
.inner-shadow {
  /* Use the 'ring-inside' utility and customize */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Adjust shadow color and size as needed */
}


button:hover {
  transform: scale(1.04);
  transition: transform 0.2s ease-in-out;
}


